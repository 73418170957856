<template>
  <div class="main-body">
    <div class="page-attention">
      <p>支持端口：PC 、H5、Android APP、IOS APP</p>
    </div>
    <a-divider />
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="支付宝应用ID" extra="[API_ID]支付宝分配给开发者的应用ID">
        <a-input v-model:value="formState.alipay.app_id" placeholder="支付宝应用ID" />
      </a-form-item>
      <a-form-item label="应用私钥">
        <a-textarea v-model:value="formState.alipay.private_key" placeholder="请输入应用私钥" />
      </a-form-item>
      <a-form-item label="应用公钥">
        <a-textarea v-model:value="formState.alipay.public_key" placeholder="请输入应用公钥" />
      </a-form-item>
      <a-form-item label="支付宝公钥">
        <a-textarea v-model:value="formState.alipay.alipay_public_key" placeholder="请输入支付宝公钥" />
      </a-form-item>
      <a-form-item label="是否启用支付">
        <a-switch v-model:checked="formState.alipay.pay_status" />
      </a-form-item>
      <a-form-item label="是否启用退款">
        <a-switch v-model:checked="formState.alipay.refund_status" />
      </a-form-item>
      <a-form-item label="是否启用转账">
        <a-switch v-model:checked="formState.alipay.transfer_status" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as Api from "@/views/payment/api";
import { message } from "ant-design-vue";

export default defineComponent({
  setup() {
    const formState = reactive({
      alipay: {
        app_id: "",
        private_key: "",
        public_key: "",
        alipay_public_key: "",
        pay_status: true,
        refund_status: true,
        transfer_status: true,
      },
    });

    const onSubmit = () => {
      console.log(formState);
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_setting({ key: "alipay" }).then((res) => {
        formState.alipay.app_id = res.data.app_id;
        formState.alipay.private_key = res.data.private_key;
        formState.alipay.public_key = res.data.public_key;
        formState.alipay.alipay_public_key = res.data.alipay_public_key;
        formState.alipay.pay_status = res.data.pay_status;
        formState.alipay.refund_status = res.data.refund_status;
        formState.alipay.transfer_status = res.data.transfer_status;
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>